import { Form, Input, Button, Checkbox, Image, Spin, message } from 'antd';
import React, { useState } from "react";
import logo from '../logo.png'
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import axios from 'axios';
async function loginUser(credentials) {
  return axios.post('https://activatewm.com/v1/user/login',credentials)
    .then(data => data)
    .catch(err=>{
      return null
    }
      )
 }
 
const Login=({setToken})=>{
    const [cookies,setCookie]=useCookies(['user'])
    const [loading,setLoading]=useState(false)
    const onFinish =async (values) => {
        setLoading(true)
        const token = await loginUser({
          email:values.email,
          password:values.password         
        });

        if(token && token.data.success){
          
          let data=token.data.data
          if(!data.status){
            setLoading(false)
            message.info('User been disabled by Admin')
            return
          }
          setCookie('id',data.admin_id,{ path: '/' })
          setCookie('name',data.name,{ path: '/' })
          setCookie('email',data.email,{ path: '/' })
          setCookie('super',data.role,{ path: '/' })
          message.success('login success')
          setToken(data.admin_id);
        }else{
          console.log(token)
          setLoading(false)
          message.error('please check credentials')
        }
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
    
    return(
       <div className='log-cont '>
           
           <div className='log-form w3-card'>
           <Spin tip='Authenticating please wait' size='large' spinning={loading}>  
           <Image
      src={logo}
    />  
           <Form
           labelCol={{ span: 5 }}
              name="basic"
              onFinish={(values)=>{
                console.log(values)
                onFinish(values)
              }}
              onFinishFailed={onFinishFailed}
              
            >
              <Form.Item
                label="Email"
                name="email"
                
                rules={[
                  {
                    required: true,
                    message: 'Please input your Email!',
                    type:'email'
                  },
                ]}
              >
                <Input  />
              </Form.Item>
        
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
               
              >
                <Input.Password />
              </Form.Item>
        
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 10,
                }}
              >
                <Button type="primary" className='btn ant-form-item-control-input' htmlType="submit">
                  Login To license
                </Button>
              </Form.Item>
            </Form>
            </Spin>
           </div>
           
       </div>
           
           
    )
} 

export default Login
Login.propTypes = {
  setToken: PropTypes.func.isRequired
};