import React, { useState,useEffect } from "react"
import "antd/dist/antd.css";
import {ArrowLeftOutlined} from '@ant-design/icons'
import { Form, Input, Button, Select, Tooltip, Divider, Space,message } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Item from "antd/lib/list/Item";
require('./sub.routes.css')
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const AdminCreate=()=>{
    const [form] = Form.useForm();
    const navigation=useNavigate()
    const location=useLocation()
    const onFinish = (values) => {
      
      const hide = message.loading('Action in progress..', 0);
      if(location.state){
        axios.post('https://activatewm.com/v1/user/update?admin_id='+location.state.data.admin_id,{
          name:values.Name,
          email:values.email,
          phone:values.mobile,
          role:values['role']=='active'?true:false,
          password:values.password,
          status:values['Status']=="active"?true:false
        }).then(res=>{
          setTimeout(hide,100)
          if(res.data.success){
            message.success('Update')
          }else{
            message.error('not updated')
          }
        }).catch(err=>{
          setTimeout(hide,100)
          err.message==='Request failed with status code 400'? message.info("Update Failed"):message.error('Internal server issue')
        })
      }else{
        axios.post('https://activatewm.com/v1/user/create',{
          name:values.Name,
          email:values.email,
          phone:values.mobile,
          role:values['role']=='active'?true:false,
          password:values.password,
          status:values['Status']=="active"?true:false
        }).then(res=>{
          setTimeout(hide,100)
          if(!res.data.success){
            message.success('Created')
          }else{
            message.error("Email already Registered")
          }
        }).catch(err=>{
          setTimeout(hide,100)
          err.message==='Request failed with status code 400'? message.info("Email already Registered"):message.error('Internal server issue')
        })
      }
     
      };
   

const onReset = () => {
        form.resetFields();
      };
    
    return (
      <div className="main-create  w3-card ">
      <div className="header">
      <div className="back">
      <Tooltip title="Back">
      <Button type="primary" shape="circle" icon={<ArrowLeftOutlined />} onClick={()=>{
         navigation('/admins',{replace:true})
      }}/>
    </Tooltip>    
      </div>
      </div>
      <Space />
      <Space />
      <Divider /> 
      <div className="data-form">
      <Form 
      initialValues={
        location.state?{
          Name:location.state.data.name,
          email:location.state.data.email,
          mobile:location.state.data.phone,
          password:location.state.data.password,
          role:location.state.data.role?'active':'deactive',
          Status:location.state.data.status?'active':'deactive',
        }:""
      }
      {...layout} 
      form={form} 
      labelAlign='left'
      name="control-hooks" 
      onFinish={onFinish}>
       
     <Form.Item
     style={{ marginBottom: 4 }}
        name="Name"
        label="Admin Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
      <Input />
      </Form.Item>
      
      <Form.Item
      style={{ marginBottom: 4 }}
        name="email"
        label="Email"
        rules={[
          {
            required: true,
          },
        ]}
      ><Input/>
      
      </Form.Item> 
      <Form.Item
      style={{ marginBottom: 4 }}
        name="mobile"
        label="Mobile"
        rules={[
          {
            required: true,
          },
        ]}
      ><Input/></Form.Item>
      <Form.Item
        style={{ marginBottom: 4 }}
        name="role"
        label="Admin Role"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Select a option and change input text above"
        >
          <Option value="active">Super-Admin</Option>
          <Option value="deactive">Sub-Admin</Option>
        </Select>
      </Form.Item>    
      <Form.Item
        style={{ marginBottom: 4 }}
        name="Status"
        label="Status"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Select a option and change input text above"
        >
          <Option value="active">Active</Option>
          <Option value="deactive">Deactive</Option>
        </Select>
      </Form.Item>
      <Form.Item
       style={{ marginBottom: 4 }}
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
       style={{ marginBottom: 2 }}
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Divider /> 
      
      <Form.Item 
      style={{ marginBottom: 2 }}
      {...tailLayout}>
        <Button type="primary" htmlType="submit" style={{marginRight:10}}>
          Create 
        </Button>
        <Button htmlType="button" onClick={onReset}>
          Reset
        </Button>
       
      </Form.Item>

    </Form>
      </div>
     </div>
      )
}
export default AdminCreate

